export const patientPreferenceOptions = [
  {
    medication: "Tirzepatide",
    options: [
      {
        name: "Tirzepatide 2.5mg",
        id: "nfAFw635atdT79W0rlDbFWO4sSOMnXJw",
        quantity: 1,
        dispense: "ml",
        refills: 0,
        strength: "2.5 MG",
      },
      {
        name: "Tirzepatide 5mg",
        id: "VjyGELD8XXZ2MokmUYBaOxnYdVwMNefT",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "5 MG",
      },
      {
        name: "Tirzepatide 7.5mg",
        id: "CoR8fkPUgTTZDK72IK4NCa0BvI5plYMq",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "7.5 MG",
      },
      {
        name: "Tirzepatide 10mg",
        id: "Wd1BQnNnvuyLyCm3RQ3PWJxIzDMSthIo",
        quantity: 2,
        dispense: "ml",
        refills: 0,
        strength: "10 MG",
      },
      {
        name: "Tirzepatide 12.5mg",
        id: "zWKeo4JOvaTfH8aqvqFQbi9dkeNKh13A",
        quantity: 2.5,
        dispense: "ml",
        refills: 0,
        strength: "12.5 MG",
      },
      {
        name: "Tirzepatide 15mg",
        id: "YHVcXLA2Z9MiJkwcc7v0NnBgcLNxo5Kg",
        quantity: 3,
        dispense: "ml",
        refills: 0,
        strength: "15 MG",
      },
    ],
  },
];

const patientPreferenceChoices = patientPreferenceOptions.reduce(
  (prev, cur) => {
    const group = cur.medication;
    const options = cur.options;
    prev.push({
      answer: {
        isText: true,
        value: group,
      },
    });
    options.forEach((opt) => {
      if (opt.isRecommended) {
        prev.push({
          answer: {
            displayName: "Let the doctor recommend",
            name: "Let the doctor recommend",
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      } else {
        prev.push({
          answer: {
            name: `${opt.name} ${opt.quantity} ${opt.dispense} - (Refills - ${opt.refills})`,
            displayName: opt.name,
            group,
            optionId: opt.id,
            optionData: opt,
          },
        });
      }
    });
    return prev;
  },
  []
);

export const questionnaire = [
  {
    question: "What is your sex assigned at birth?",
    answers: [
      // TODO: Pregnant + breastfeeding Follow Up Q
      {
        answer: "Female",
      },
      {
        answer: "Male",
      },
    ],
    type: "radio",
    standardDemo: "sex",
  },
  {
    question: "What is your height?",
    subText: "Height in inches",
    answers: ["number"],
    type: "number",
    standardDemo: "height",
    isConditional: false,
  },
  {
    question: "What is your weight?",
    subText: "Weight in pounds (lbs)",
    answers: ["number"],
    type: "number",
    standardDemo: "weight",
    isConditional: false,
  },
  {
    question:
      "Please list what you're allergic to and the reaction that each allergy causes.",
    answers: ["allergies"],
    subText:
      "Include any allergies to food, dyes, prescriptions or over the counter medicines (e.g. antibiotics, allergy medications), herbs, vitamins, supplements or anything else.",
    type: "freeText",
    standardDemo: "allergies",
    isConditional: false,
  },
  {
    question: "Please list your medical conditions and any prior surgeries:",
    answers: ["medicalConditions"],
    type: "freeText",
    standardDemo: "medicalConditions",
    isConditional: false,
  },
  {
    question:
      "Please list any current medicines, vitamins or dietary supplements you take regularly.",
    subText: "Please include the dosage.",
    answers: ["selfReportedMeds"],
    type: "freeText",
    standardDemo: "selfReportedMeds",
    isConditional: false,
  },
  {
    question:
      "Have you tried or are you currently using any medications to help with weight loss?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question: "Which medications, which dose, and when was the last dose you took?",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question:
      "Are you likely to increase your physical fitness and activity, in addition to taking your new medication, if prescribed?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question:
      "Do you have (or have you had) any of the following conditions? Choose All Applicable",
    answers: [
      {
        answer: "Alcohol overuse",
        showConditional: false,
      },
      {
        answer: "Admitted to a psychiatric facility",
        showConditional: false,
      },
      {
        answer: "Attempted suicide",
        showConditional: false,
      },
      {
        answer: "Bariatric surgery",
        showConditional: false,
      },
      {
        answer: "Bipolar disorder",
        showConditional: false,
      },
      {
        answer: "Bleeding or clotting disorder",
        showConditional: false,
      },
      {
        answer: "Chronic kidney (renal) failure",
        showConditional: false,
      },
      { answer: "Liver disease", showConditional: false },
      { answer: "Depression", showConditional: false },
      { answer: "Diabetes", showConditional: false },
      { answer: "Illicit Drug use", showConditional: false },
      { answer: "Eating disorders (anorexia/bulimia)", showConditional: false },
      { answer: "Gallbladder disease", showConditional: false },
      { answer: "Heart attack", showConditional: false },
      { answer: "Heart disease", showConditional: false },
      { answer: "High blood pressure", showConditional: false },
      { answer: "High cholesterol", showConditional: false },
      {
        answer: "Infection/tumor in your brain or spinal cord",
        showConditional: false,
      },
      { answer: "Low blood sugar (hypoglycemia)", showConditional: false },
      { answer: "Mental illness", showConditional: false },
      { answer: "Pancreatitis", showConditional: false },
      {
        answer:
          "A personal or family history of medullary thyroid carcinoma or multiple endocrine neoplasia syndrome type 2",
        showConditional: false,
      },
      {
        answer: "Recently thoughts of hurting yourself or others",
        showConditional: false,
      },
      { answer: "Seizures", showConditional: false },
      { answer: "Sleep apnea", showConditional: false },
      { answer: "Stroke", showConditional: false },
      { answer: "Thyroid dysfunction", showConditional: false },
      { answer: "None of these apply", showConditional: false },
    ],
    type: "checkBox",
  },
  {
    question:
      "Has you or anyone in your family been diagnosed with thyroid cancer?",
    answers: [
      {
        answer: "Yes",
        showConditional: false,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
  },
  {
    question:
      "Is there anything else you want your doctor to know about your condition or health?",
    answers: [
      {
        answer: "Yes",
        showConditional: true,
      },
      {
        answer: "No",
        showConditional: false,
      },
    ],
    type: "radio",
    isConditional: false,
  },
  {
    question:
      "Please enter anything else you want your doctor to know about your condition or health.",
    answers: ["freeText"],
    type: "freeText",
    isConditional: true,
  },
  {
    question: "Please Select An Option",
    type: "radio",
    answers: patientPreferenceChoices,
    standardDemo: "patientPreference",
  },
];
