/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";

import MainForm from "./MainForm/MainForm";
import IntroPage from "./IntroPage/IntroPage";
import SuccessPage from "./SuccessPage/SuccessPage";

import { questionnaire } from "./DataFiles/formArrays";

import "./App.css";

const App = () => {
  const [formData, setFormData] = useState({});
  const [visitId, setVisitId] = useState("");
  const [onIntro, setOnIntro] = useState(true);
  const [page, setPage] = useState(0);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const goToForm = () => {
    navigate("/form");
    setOnIntro(false);
  };

  useEffect(() => {
    if (onIntro) {
      if (searchParams.get("visit")) {
        // console.log("visitId", searchParams.get("visit"));
        setVisitId(searchParams.get("visit"));
      }
      navigate("/");
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        {onIntro ? (
          <Route
            exact
            path="/"
            element={
              <IntroPage
                goToForm={goToForm}
                formData={formData}
                setFormData={setFormData}
              />
            }
          />
        ) : (
          <>
            <Route
              path="/form"
              element={
                <MainForm
                  visitId={visitId}
                  formData={formData}
                  setFormData={setFormData}
                  questionnaire={questionnaire}
                  page={page}
                  setPage={setPage}
                />
              }
            />
            <Route path="/success" element={<SuccessPage />} />
          </>
        )}
      </Routes>
    </div>
  );
};

export default App;
