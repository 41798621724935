// icon:close-circle | Ant Design Icons https://ant.design/components/icon/ | Ant Design
import * as React from "react";

function IconCloseCircle(props) {
  return (
    <svg style={{backgroundColor: "white", borderRadius: "50%"}} viewBox="0 0 512 512" fill="black" height="1em" width="1em" {...props}>
      <path d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm75.31 260.69a16 16 0 11-22.62 22.62L256 278.63l-52.69 52.68a16 16 0 01-22.62-22.62L233.37 256l-52.68-52.69a16 16 0 0122.62-22.62L256 233.37l52.69-52.68a16 16 0 0122.62 22.62L278.63 256z" />
    </svg>
  );
}

export default IconCloseCircle;
